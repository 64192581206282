<template>
	<div>
		<div class="card mb-3" v-for="(item, i) in items" :key="i">
			<div
				class="card-header card-collapse"
				@click="item.expanded = !item.expanded"
			>
				<h5 class="mb-0">
					<button
						type="button"
						class="btn btn-block d-flex justify-content-between card-btn p-3"
					>
						{{ item.question }}

						<span class="card-btn-toggle">
							<template v-if="item.expanded">
								-
							</template>
							<template v-else>
								+
							</template>
						</span>
					</button>
				</h5>
			</div>
			<CollapseTransition>
				<div class="collapse show" v-if="item.expanded">
					<div
						class="card-body"
						v-if="item.question != 'What is Thriftax’s privacy policy?'"
						v-html="item.answer"
					></div>
					<div class="card-body" v-else>
						In short, we will never sell your information. You may decide
						whether you want to receive tailored offers from our partners
						directly from your dashboard, but your information will not be
						shared with any 3rd party. In addition, our site is hosted and by
						AWS with the highest security protocols. You may review our privacy
						policy
						<a
							href="javascript:;"
							@click="$router.push({ name: 'PrivacyPolicy' })"
							>here</a
						>.
					</div>
				</div>
			</CollapseTransition>
		</div>
	</div>
</template>


<script>
import { CollapseTransition } from '@ivanv/vue-collapse-transition';

export default {
    components: {
        CollapseTransition,
    },
    data() {
        return {
            items: [
                {
                    question: `Can I use Thriftax?`,
                    answer: `If you are a U.S. nonresident alien, Thriftax is made for you! 
                    In most cases, if you are on an F, J, M, or Q visa, you are considered a nonresident alien. 
                    You might also be a nonresident alien if you are in the U.S. on an H1 (such as H-1B), H2 
                    (such as H-2B) or H4 visa for the first year. You may also be a nonresident on other visa types, 
                    depending on the time you were present in the U.S.`,
                    expanded: false,
                },
                {
                    question: `How do I know if I'm a nonresident alien or a resident alien?`,
                    answer: `Thriftax will assess your residency in the first stage of the tax filing process.
                    If you are a nonresident alien, you’ll be able to proceed and complete the filing process.
                     However, if our software determines that you're a resident alien, you’ll be directed to use a 
                     resident tax return software.`,
                    expanded: false,
                },
                {
                    question: `Can I file with TurboTax or H&R Block as a nonresident Alien?`,
                    answer: `If your status is non-resident alien, you cannot use online tax filing 
					solutions for residents such as TurboTax or H&R Block. These providers will file form 
					1040 for you rather than the required 1040-NR form. Moreover, if are exempt from FICA taxes under your 
					F, J, M or Q visa and file your taxes as a resident alien (form 1040) your mistake may be 
					considered as a tax fraud! Comply with your residency status and file accordingly.`,
                    expanded: false,
                },
                {
                    question: `I am a student on an F-1 / J-1 visa. When will I be considered a resident alien?`,
                    answer: `Generally, you’ll be considered a nonresident alien for 5 years. Thus, in your 6th 
                    year in the U.S. on that visa, you’ll be considered a resident alien.`,
                    expanded: false,
                },
                {
                    question: `Which form should I file as a nonresident alien?`,
                    answer: `Nonresidents are required to file form 1040NR. Notice that this form is NOT 
                    supported by TurboTax, H&R Block and other tax software solutions designated for U.S. residents
                    and resident aliens filing form 1040. Filing the wrong form may result in fees, fines and extra 
                    expenses for filing an amended tax return. Thriftax is made for nonresidents and designed 
                    to support their tax filing requirements, including additional schedules and forms such as form 
                    8843. `,
                    expanded: false,
                },
                {
                    question: `Can I file form 1040NR-EZ?`,
                    answer: `Unfortunately, form 1040NR-EZ was cancelled by the IRS in 2020. All nonresidents are 
                    now required to file form 1040NR. `,
                    expanded: false,
                },
                {
                    question: `Can I file with Thriftax if I am currently completing my OPT (for F-1) or 
                    Academic Training (for J-1)?`,
                    answer: `Yes! Even if your visa stamp is expired, if you are completing OPT (STEM and NON-STEM) 
                    or Academic Training, you should have a valid I-20 (F-1) / DS-2019 (J-1) form and can 
                    file with Thriftax.`,
                    expanded: false,
                },
                {
                    question: `How do I file with Thriftax?`,
                    answer: `After signing up with Thriftax and beginning the filing process, Thriftax will 
                    ask you simple questions using easy-to-understand words. The questionnaire is adjustable, 
                    meaning you only see questions that are relevant to you and your case. Thriftax will use 
                    tax treaties and ask you about deductions you qualify for to maximize your tax refund. After 
                    completing checkout, you’ll be able to print your tax forms along with our simple 
                    instructions on where to sign and how to mail them to the tax authorities. That’s it! `,
                    expanded: false,
                },
                {
                    question: `Do I have to file tax returns?`,
                    answer: `If you have income originating from the US, including but not limited to 
                    employment income, scholarship or fellowship grants, prizes and awards, interest income, 
                    capital gains (including those made by cryptocurrency transactions) or any other type of 
                    income, you must file a tax return! <br/> <br/> If you had no U.S. income, you 
                    don’t need to file form 1040NR,
                    but if you are in the U.S. on an F, J, M or Q visa, you must file form 8843 and mail it 
                    to the IRS. Form 8843 is supported by Thriftax and our software will 
                    inform you if you are required to file it.`,
                    expanded: false,
                },
                {
                    question: `What is the deadline to file and mail my nonresident tax return?`,
                    answer: `You should file and mail your tax return forms to the IRS by April 15th. 
                    If you are only required to file form 8843, you should mail it by Jun 15th.  `,
                    expanded: false,
                },
                {
                    question: `When will I get my tax refund?`,
                    answer: `Usually, the IRS will process your tax return within 8 weeks or less. In 
                    some cases, it may take longer. Do not mail your tax return forms again if your refund 
                    payment is being delayed. Typically, the earlier you file, the faster your refund will 
                    be processed. Please note that Thriftax cannot control your return processing and refund 
                    issuance speed. `,
                    expanded: false,
                },
                {
                    question: `What is Thriftax’s privacy policy?`,
                    answer: `IN TEMPLATE`,
                    expanded: false,
                },
                {
                    question: `Do you provide support?`,
                    answer: `Absolutely! Throughout the filing process, you’ll see many tooltips 
                    and hints to help you feel comfortable and to provide you guidance for how to answer 
                    the questions. If you are feeling that you need specific help, you may submit a 
                    support request 24/7 from any page in the filing process or from the dashboard.`,
                    expanded: false,
                },
                {
                    question: `Can I review my refund information prior to checking out?`,
                    answer: `Yes, you will be able to see your tax refund amount before checking out. 
                    In some cases, if your employer or income payer did not withhold enough taxes for you, 
                    you may see a tax debt which you’ll be required to pay to the tax authorities. If 
                    that is the case, you are still required by law to file and mail your tax return 
                    as well as pay any tax balance due.`,
                    expanded: false,
                },
                {
                    question: `What payment options are available?`,
                    answer: `Currently we are supporting the following payment options: debit cards, 
                    credit cards, and PayPal. Your payment information is encrypted and not being 
                    saved on our servers. `,
                    expanded: false,
                },
                {
                    question: `How will I receive my tax forms after checking out?`,
                    answer: `After checking out, your forms will be available for you to download. 
                    We don’t email your forms for security reasons. We may email your forms to you upon 
                    request, however it is recommended that you delete the email from your inbox after 
                    downloading them.`,
                    expanded: false,
                },
                {
                    question: `What should I do after downloading my forms?`,
                    answer: `Sign in the designated places (wet ink signature is required) and 
                    mail your returns to the IRS and, if required, any state tax authorities. 
                    Thriftax will provide you with simple and detailed instructions for how 
                    to handle and mail your forms.  `,
                    expanded: false,
                },
                {
                    question: `How will I know if I need to file a tax return?`,
                    answer: `Some states in the US impose income taxes on top of the federal taxes. 
                    You may be required to file state tax returns as well based on the state you 
                    lived or made your income in. Thriftax will let you know if you are required 
                    to file a state tax return. If you are required to file a state return, you will 
                    be able to complete filing with Thriftax as well. `,
                    expanded: false,
                },
                {
                    question: `Which income forms are supported?`,
                    answer: `Thriftax currently supports the following income forms: W-2, 1042-S, 
                    1099-G, 1099-INT, 1099-B, 1099-DIV, 1099-NEC, and 1099-MISC. In addition, you can report other 
                    types of income such as scholarships, fellowships, awards and prizes. More income 
                    forms are constantly being added based on demand and need, so we recommend you check 
                    frequently for updates.`,
                    expanded: false,
                },
                {
                    question: `How can I make money with Thriftax?`,
                    answer: `Apart from helping you maximize your tax refund, we are offering a 
                    referral program that will pay you for every referral you make that purchases a 
                    tax return from Thriftax. You may mass-refer by sharing your designated link with groups 
                    and your networks or text / email your link to a specific friend. Your referred 
                    friends will get 10% off for their purchase and you will receive 10% of their 
                    spending. If you would like to be promoter of Thriftax in your school, workplace 
                    or other social groups and make even more, please contact us for more information.`,
                    expanded: false,
                },
            ],
        };
    },
};
</script>
