<template>
	<div class="landing-wrapper">
		<t-header />
		<div class="space-top-lg-3 space-top-2"></div>

		<div class="container mb-8">
			<div class="mx-auto col-lg-10 col-xl-8">
				<h2 class="mb-4">Frequently Asked Questions</h2>
				<FAQBase />
				<p class="lead">
					Still have a question?
					<a href="javascript:;" @click="$router.push({ name: 'Contact' })"
						>Contact us</a
					>.
				</p>
				<ReadyToFileBanner />
			</div>
		</div>
        <t-footer />
	</div>
</template>

<script>
import FAQBase from "@/views/faq";
import ReadyToFileBanner from "@/components/landings/ready-to-file-banner";

export default {
	components: {
		FAQBase,
		ReadyToFileBanner,
	},
	metaInfo: {
		title: "Thriftax - FAQs",
		meta: [
			{
				name: 'description',
				content: 'Nonresident filing requirements, who needs to file, tax refund timelines and more. Get answers to all your nonresident tax filing questions and learn how Thriftax can help you file your taxes',
			},
		],
	},
};
</script>
